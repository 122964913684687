const Cookie = {
  setCookie(cname: string, cvalue: string, time?: string) {
    let keyname = `parking_${cname}`;
    if (time) {
      let expires = ';expires=' + new Date(time);
      document.cookie = keyname + '=' + cvalue + expires + ';path=/';
    } else {
      document.cookie = keyname + '=' + cvalue + ';path=/';
    }
  },
  getCookie(cname: string) {
    let keyname = `parking_${cname}`;
    let name = keyname + '=';
    let ca = document.cookie.split(';');
    for (let item of ca) {
      let c = item.trim();
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  },
};

export default Cookie;
