// 账号权限
export enum EnumAuthType {
  ADMIN = 1, // 超管
  MANAGE = 2, // 管理员
}
export enum EnumVideoType {
  FITNESS = 1,
  GYMNASTICS = 2,
  TAIJI = 3
}
// 大屏运动排行类型
export enum EnumRankType {
  DAY = 1,
  MONTH = 2,
  ALL = 3
}