import React from 'react';
import type { RouteObject } from 'react-router-dom';
import loadable from './components/Loadable';

const ScreenLayout = loadable(() => import('./views/ScreenLayout/ScreenLayout'));
const ScreenIndex = loadable(() => import('./views/ScreenIndex/ScreenIndex'));
const ScreenMap = loadable(() => import('./views/ScreenMap/ScreenMap'));
const ScreenVideo = loadable(() => import('./views/ScreenVideo/ScreenVideo'));
const ScreenRank = loadable(() => import('./views/ScreenRank/ScreenRank'));
const ScreenXuexi = loadable(() => import('./views/ScreenXuexi/ScreenXuexi'));
const LoginPage = loadable(() => import('./views/Login/Login'));
const Layout = loadable(() => import('./views/Layout/Layout'));
const IndexPage = loadable(() => import('./views/Index/Index'));
const InfoMiniprogram = loadable(() => import('./views/InfoMiniprogram/InfoMiniprogram'));
const InfoScreen = loadable(() => import('./views/InfoScreen/InfoScreen'));
const InfoFeedBack = loadable(() => import('./views/InfoFeedback/InfoFeedback'));
const DevicePage = loadable(() => import('./views/Device/Device'));
const AccountPage = loadable(() => import('./views/Account/Account'));
const SystemPage = loadable(() => import('./views/System/System'));

const routes: RouteObject[] = [
  // 大屏
  {
    path: '/screen',
    element: <ScreenLayout />,
    children: [
      {
        path: '/screen/index',
        element: <ScreenIndex />,
      },
      {
        path: '/screen/map',
        element: <ScreenMap />,
      },
      {
        path: '/screen/video',
        element: <ScreenVideo />,
      },
      {
        path: '/screen/rank',
        element: <ScreenRank />,
      },
      {
        path: '/screen/xuexi',
        element: <ScreenXuexi />,
      },
    ],
  },
  // 管理平台
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: 'index',
        element: <IndexPage />,
      },
      {
        path: 'info',
        children: [
          {
            path: 'miniprogram',
            element: <InfoMiniprogram />,
          },
          {
            path: 'screen',
            element: <InfoScreen />,
          },
          {
            path: 'feedback',
            element: <InfoFeedBack />
          }
        ],
      },
      {
        path: 'device',
        children: [
          {
            path: 'maintenance',
            element: <DevicePage />,
          },
        ],
      },
      {
        path: 'system',
        children: [
          {
            path: 'account',
            element: <AccountPage />,
          },
          {
            path: 'management',
            element: <SystemPage />,
          },
        ],
      },
    ],
  },
];
export default routes;
