import { createAsyncThunk } from '@reduxjs/toolkit';
import { get, post } from './request';
import { RsaEncryption } from '../utils/general';

export const login = createAsyncThunk('common/login', async (data: LoginParams) => {
  const params = {
    ...data,
    password: RsaEncryption(data.password),
  };
  return await post('admin/login', params);
});

export const logout = createAsyncThunk('common/logout', async () => await get('admin/logout'));
// logo获取
export const getLogo = createAsyncThunk('common/logo', async () => await get('admin/data_maintenance/logo'));

interface LoginParams {
  mobile: string;
  password: string;
  remember: boolean;
}
