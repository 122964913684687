import React from 'react';
import { createSlice } from '@reduxjs/toolkit';
import { login, logout, getLogo } from '../api/common';
import Cookie from '../utils/cookie';
import { EnumAuthType } from '../typings/enum';

export interface CommonState {
  breadcrumbList: { label: string; key: string; icon?: string }[];
  userInfo: {
    id: number;
    name: string;
    token: string;
    expireTime: number;
    permissionCode: EnumAuthType;

  };
  routes: string[];
  logoUrl: string;
  loading: boolean;
}

const initialState: CommonState = {
  breadcrumbList: [],
  userInfo: {
    id: 0,
    name: '',
    token: '',
    expireTime: 0,
    permissionCode: EnumAuthType.MANAGE
  },
  routes: [
    '/index',
    '/info',
    '/info/miniprogram',
    '/info/screen',
    '/info/feedback',
    '/device',
    '/device/maintenance',
    '/system',
    '/system/account',
    '/system/management',
  ],
  logoUrl: '',
  loading: false
};

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    updateBreadcrumbList: (state, action) => {
      state.breadcrumbList = action.payload;
    },
    toggleLoading: (state, action) => {
      state.loading = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, { payload, meta }) => {
      if (payload.code === 0) {
        const {routeUrl, ...rest} = payload.data;
        state.userInfo = rest;
        state.routes = routeUrl;
        if (meta.arg.remember) {
          Cookie.setCookie('token', payload.data.token, payload.data.expireTime);
        } else {
          Cookie.setCookie('token', payload.data.token);
        }
      }
    });
    builder.addCase(logout.fulfilled, (state, { payload }) => {
      if (payload.code === 0) {
        state.userInfo = {
          id: 0,
          name: '',
          token: '',
          expireTime: 0,
          permissionCode: EnumAuthType.MANAGE
        };
        Cookie.setCookie('token', '');
      }
    });
    builder.addCase(getLogo.fulfilled, (state, { payload }) => {
      if (payload.code === 0) {
        state.logoUrl = payload.data.logoUrl;
      }
    })
  },
});

// Action creators are generated for each case reducer function
export const { updateBreadcrumbList, toggleLoading } = commonSlice.actions;

export default commonSlice.reducer;
