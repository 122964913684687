import React from 'react';
import loadable from '@react-loadable/revised';

const loadingComponent = ({ error, pastDelay }: any) => {
  if (error) {
    return <div>error</div>;
  } else if (pastDelay) {
    return <div>loading...</div>;
  }
  return null;
};

export default (loader: any, loading = loadingComponent) => {
  return loadable({
    loader,
    loading,
  });
};
