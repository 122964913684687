import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/locale/zh_CN';
import App from './App';
import 'antd/dist/reset.css';
import { store } from './store/store';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <BrowserRouter>
    <ConfigProvider
      locale={zhCN}
      theme={{
        token: {
          colorPrimary: '#931937',
          borderRadius: 4,
        },
        components: {
          Button: {
            controlHeight: 35,
            controlHeightSM: 32,
            controlHeightLG: 50,
            colorBorder: 'rgba(0,0,0,0.2)',
            colorText: '#464646',
            fontSizeLG: 20,
          },
          Breadcrumb: {
            colorTextDescription: 'rgba(43, 43, 43, 1)',
            fontSize: 24,
          },
          Checkbox: {
            fontSize: 16,
            colorText: '#464646',
            // controlInteractiveSize: 20,
          },
          DatePicker: {
            controlHeightLG: 50,
            fontSizeLG: 20,
            colorLink: '#931937',
            colorLinkActive: '#931937',
            colorLinkHover: '#931937',
          },
          Dropdown: {
            colorText: '#464646',
          },
          Modal: {
            fontSizeHeading5: 24,
            colorTextHeading: '#464646',
            colorWarning: '#931937',
          },
          Input: {
            controlHeight: 35,
            controlHeightSM: 32,
            controlHeightLG: 50,
            fontSizeLG: 20,
            colorText: '#464646',
          },
          InputNumber: {
            controlHeight: 35,
            controlHeightSM: 32,
            controlHeightLG: 50,
          },
          Pagination: {
            borderRadius: 2,
            colorText: '#2F3133',
            colorBorder: '#CED4DB',
          },
          Radio: {
            colorText: '#464646',
            fontSize: 16,
          },
          Select: {
            controlHeightLG: 50,
            fontSizeLG: 20,
          },
          Table: {
            colorText: '#464646',
            colorTextHeading: '#464646',
            padding: 12,
          },
        },
      }}
    >
      <Provider store={store}>
        <App />
      </Provider>
    </ConfigProvider>
  </BrowserRouter>,
);
