import JSEncrypt from 'jsencrypt';

let UIfontSize = 1;
function Htmi_UI_Resize() {
  UIfontSize = (window.document.documentElement.clientHeight / 1080) * 100;
  document.getElementsByTagName('html')[0].style.fontSize = UIfontSize + 'px';
}

let resizeDisping = false;
function Resize_Disp() {
  if (!resizeDisping) {
    resizeDisping = true;
    // 窗口发生变化自动适配
    window.onresize = () => {
      if (window.resizeFlag) {
        clearTimeout(window.resizeFlag);
      }

      window.resizeFlag = setTimeout(() => {
        Htmi_UI_Resize();
        // console.log("re");
        window.resizeFlag = null;
      }, 300);
    };
  }
}

export function InitUIScene() {
  // console.log("InitUIScene");
  if (!resizeDisping) {
    Htmi_UI_Resize();
    // Prototype_extend();
    Resize_Disp();
  }
}
export function RemToPx(num: number) {
  return num * UIfontSize;
}

// 密码加密公钥
export const PUBLIC_KEY =
  'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCLu55Ja6pXaw/IQmt/08azFndXhRv9zz+LzbMVYcWtJ7hJiFLMLUFg6oQYrNC2vTwAtf/njbUh63NbmcCUfgwJNHibdkT58Z53wTKq60W7YZmxkt9PENWEfmV9bSdGXryA+t9AkIR0jGw4jJ3OWWLz3ZQ3m99VTe3099fSYSYcRwIDAQAB';

export function RsaEncryption(pwd: string): string {
  const encryptor = new JSEncrypt();
  encryptor.setPublicKey(PUBLIC_KEY);
  return encryptor.encrypt(pwd) as string;
}
