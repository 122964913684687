import qs from 'qs';
import Cookie from '../utils/cookie';
import { store } from '../store/store';
import { message } from 'antd';
import { toggleLoading } from '../store/commonSlice';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const version = 'v1';

export function request({ api, method, data, form }: RequestOption) {
  let url = `${BASE_URL}/${version}/${api}`;
  const cfg: any = {
    headers: {
      Authorization: Cookie.getCookie('token'),
    },
    method,
  };
  if (!form) {
    cfg.headers['Content-Type'] = 'application/json; charset=utf-8';
  }
  if (data) {
    if (method !== 'GET' && method !== 'DELETE') {
      cfg.body = form ? data : JSON.stringify(data);
    } else {
      url += `?${qs.stringify(data)}`;
    }
  }
  store.dispatch(toggleLoading(true))
  return fetch(url, cfg)
    .then((response) => {
      if (!response.ok) {
        message.error('Network response was not OK');
        throw Error('Network response was not OK');
      }
      return response.json();
    })
    .then((res) => {
      store.dispatch(toggleLoading(false))
      if (res.code === 401 && window.location.pathname !== '/login') {
        window.location.href = '/login';
      }
      if (res.code !== 0) {
        message.error(res.message || res.errmsg);
      }
      return Promise.resolve(res);
    });
}

export function get(api: string, data?: any) {
  return request({
    method: 'GET',
    api,
    data,
  });
}
export function post(api: string, data?: any, form?: boolean) {
  return request({
    method: 'POST',
    api,
    data,
    form,
  });
}
export function put(api: string, data?: any,  form?: boolean) {
  return request({
    method: 'PUT',
    api,
    data,
    form
  });
}
export function del(api: string, data?: any) {
  return request({
    method: 'DELETE',
    api,
    data,
  });
}
export function download(api: string, data?: any, name?: string) {
  let url = `${BASE_URL}/${version}/${api}`;
  const cfg: any = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: Cookie.getCookie('token'),
    },
    method: 'GET',
  };
  if (data) {
    url += `?${qs.stringify(data)}`;
  }
  return fetch(url, cfg)
    .then((response) => {
      if (!response.ok) {
        throw Error('Network response was not OK');
      }
      return response;
    })
    .then((res) => {
      const fileName = res.headers.get('Content-disposition')?.split('filename=')[1];
      res.arrayBuffer().then((arraybuffer) => {
        let blob = new Blob([arraybuffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        if (fileName) {
          link.download = decodeURIComponent(fileName);
        }
        link.click();
        window.URL.revokeObjectURL(link.href);
      });
    });
}
interface RequestOption {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  api: string;
  data?: any;
  headers?: any;
  form?: boolean;
}
